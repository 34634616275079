import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useNavigate } from 'react-router-dom';
import { Badge, Card } from '@mui/material';
import { Star, StarOutlineOutlined } from '@mui/icons-material';
import { addFavorite, removeFavorite } from '../../utils/favorite-util';

/**
 * FarmStatus 에 따라 아이콘을 다르게 나타낸다.
 * FarmStatus 는 enum으로 Working 은 0, Error 는 1, Fault 는 2이다.
 */
const selectFarmIcon = [
    <CancelRoundedIcon className="text-fault" />,
    <CheckCircleRoundedIcon className="text-working" />,
];

type SidebarListItemProp = {
    recent?: boolean;
    status: boolean;
    title: string;
    farmID: number;
    setSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    mode?: string;
    modeChanged?: number;
    favorite?: boolean;
    refreshSidebar?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * 사이드바 내부의 아이템의 컴포넌트
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.recent - 3일전에 스마트팜의 모드가 변경되었는지 여부
 * @param {string} props.status - 현재 스마트팜의 모드
 * @param {string} props.title - 스마트팜 이름
 * @param {string} props.farmID - 스마트팜 ID
 * @param {Function} props.setSidebar - 사이드바 열림/닫힘 설정
 * @returns {JSX.Element} 사이드바에 들어갈 스마트홈 리스트 아이템 컴포넌트
 */
const SidebarListItem: React.FC<SidebarListItemProp> = ({
    recent = false,
    status,
    title,
    farmID,
    setSidebar,
    mode,
    modeChanged,
    favorite,
    refreshSidebar,
}) => {
    const navigate = useNavigate();

    const clickHandler = (): void => {
        setSidebar(false);
        navigate(`/${farmID}`);
    };

    const favoriteHandler = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        if (favorite) {
            removeFavorite(farmID);
        } else {
            addFavorite(farmID);
        }
        refreshSidebar?.((prev) => !prev);
    };

    return (
        <Badge
            invisible={!recent}
            className="flex"
            color="error"
            badgeContent=""
            data-testid="recent-badge"
        >
            <Card
                className="0.3s flex-1 cursor-pointer rounded-lg bg-[#DEE2E6AA] px-2 py-2 transition ease-in-out hover:bg-[#ADB5BD]"
                onClick={clickHandler}
            >
                <div className="flex items-center justify-between">
                    <div className="break-keep text-lg font-bold text-[black]">
                        {title}
                    </div>
                    <div className="flex">
                        <div
                            className="flex items-center justify-between"
                            data-testid="status-icon"
                        >
                            {selectFarmIcon[Number(status)]}
                        </div>
                        <div
                            className={`flex text-2xl ${favorite ? 'text-[#ffe234]' : 'text-[black]'} cursor-pointer`}
                            onClick={favoriteHandler}
                        >
                            {favorite ? (
                                <Star fontSize="inherit" />
                            ) : (
                                <StarOutlineOutlined fontSize="inherit" />
                            )}
                        </div>
                    </div>
                </div>
                {modeChanged !== undefined && (
                    <div className="flex items-center gap-2">
                        <p className="font-bold">{mode}</p>
                        <p>-</p>
                        <p className="font-bold">{modeChanged}일</p>
                    </div>
                )}
            </Card>
        </Badge>
    );
};

export default SidebarListItem;
