/**
 * 현재 날짜 및 이전 날짜와 차이 등을 계산하는 함수들을 모아놓은 파일.
 */

/**
 * ISO 8601 형식으로 최근에 모드가 변경된 날짜가 주어질때, 현재 시간과의 차이를 일 단위로 반환하는 함수
 * @param date
 * @returns
 */
export const timeDifference = (date: string): number => {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffDay = new Date(Math.abs(date2.getTime() - date1.getTime()));
    return Math.round(diffDay.getTime() / 1000 / 60 / 60 / 24);
};

/**
 * ISO 8601 형식으로 현재 시간으로부터 hour 시간 전의 시간을 반환하는 함수
 * @param hour
 * @returns
 */
export const hourBefore = (hour: number): string => {
    const date = new Date();
    date.setHours(date.getHours() - hour);
    return date.toISOString();
};

/**
 * ISO 8601 형식으로 현재시간을 반환하는 함수
 */
export const now = (): string => {
    return new Date().toISOString();
};
