/**
 * 상세 화면에서 개별 양액기
 */
import React, { useEffect, useRef, useState } from 'react';
import { Wifi, WifiOff } from '@mui/icons-material';
import axios from 'axios';
import { Chart, Point } from 'chart.js';
import { Skeleton } from '@mui/material';
import LineChart, { LineChartData } from '../LineChart/LineChart';
import { hourBefore, now } from '../../utils/datetime-util';

type NutrientProp = {
    range: number;
    deviceId: string;
    connected: boolean;
    name: string;
    targetEC: number | undefined;
    targetpH: number | undefined;
};

/**
 * Range 별 시간을 담은 배열
 */
const rangeHour = [3, 24, 72, 168];

/**
 * 양액기의 차트를 표기하는 컴포넌트
 *
 * @component
 * @param {Object} props
 * @param {number} props.range - 차트에 표기할 시간의 범위, 현재 시간으로부터 몇시간 전까지 표기할지
 * @param {string} props.deviceId - 장치 ID
 * @param {boolean} props.connected - 장치 연결 상태
 * @param {string} props.name - 장치 이름
 * @param {number} props.targetEC - 목표 EC
 * @param {number} props.targetPH - 목표 PH
 * @returns {JSX.Element} 차트를 포함한 장치의 정보
 */
const Nutrient: React.FC<NutrientProp> = ({
    range,
    deviceId,
    connected,
    name,
    targetEC,
    targetpH,
}) => {
    const [phChart, setPhChart] = useState<LineChartData[] | undefined>(
        undefined
    );
    const [ecChart, setEcChart] = useState<LineChartData[] | undefined>(
        undefined
    );
    const [waterTempChart, setWaterTempChart] = useState<
        LineChartData[] | undefined
    >(undefined);

    const ecRef = useRef<
        Chart<'line', (number | Point | null)[], unknown> | undefined
    >();

    const phRef = useRef<
        Chart<'line', (number | Point | null)[], unknown> | undefined
    >();

    const wtRef = useRef<
        Chart<'line', (number | Point | null)[], unknown> | undefined
    >();

    const [chartLoading, setChartLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchChart = async (): Promise<void> => {
            setChartLoading(true);
            setEcChart(undefined);
            setPhChart(undefined);
            setWaterTempChart(undefined);

            const ecph = await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/ecph/chart?deviceID=${deviceId}&fromTime=${hourBefore(rangeHour[range])}&toTime=${now()}`
                )
                .then((res) => res.data)
                .catch(() => undefined);

            if (ecph !== undefined) {
                setPhChart(
                    ecph.map(
                        (data: any): LineChartData => ({
                            measuredAt: data.time,
                            data: data.ph,
                        })
                    )
                );

                setEcChart(
                    ecph.map((data: any) => ({
                        measuredAt: data.time,
                        data: data.ec,
                    }))
                );

                setWaterTempChart(
                    ecph.map((data: any) => ({
                        measuredAt: data.time,
                        data: data.waterTemperature,
                    }))
                );

                localStorage.setItem(
                    'phChart',
                    JSON.stringify(
                        ecph.map(
                            (data: any): LineChartData => ({
                                measuredAt: data.time,
                                data: data.ph,
                            })
                        )
                    )
                );

                localStorage.setItem(
                    'ecChart',
                    JSON.stringify(
                        ecph.map((data: any) => ({
                            measuredAt: data.time,
                            data: data.ec,
                        }))
                    )
                );

                localStorage.setItem(
                    'waterTempChart',
                    JSON.stringify(
                        ecph.map((data: any) => ({
                            measuredAt: data.time,
                            data: data.waterTemperature,
                        }))
                    )
                );
            } else {
                setPhChart(undefined);
                setEcChart(undefined);
                setWaterTempChart(undefined);
            }

            setChartLoading(false);
        };
        fetchChart();
    }, [range, deviceId]);

    return (
        <div className="flex-1">
            <div className="mb-4 flex items-center justify-between gap-4 rounded-sm border-[1px] border-grayscale-500 bg-grayscale-200 p-2 shadow-around lg:w-[400px]">
                <p className="text-xl font-bold">{name}</p>
                {connected ? <Wifi /> : <WifiOff className="text-fault" />}
            </div>
            {chartLoading && (
                <section className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <div className="flex flex-col gap-4">
                        <Skeleton variant="rectangular" height={'192px'} />
                        <Skeleton variant="rectangular" height={'192px'} />
                    </div>
                    <Skeleton variant="rectangular" height={'192px'} />
                </section>
            )}
            {!chartLoading &&
                ecChart !== undefined &&
                phChart !== undefined &&
                waterTempChart !== undefined && (
                    <div className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
                        <div className="flex flex-col gap-4">
                            <div>
                                <LineChart
                                    title="ec"
                                    unit={range <= 1 ? 'hour' : 'day'}
                                    step={range === 1 ? 4 : 1}
                                    data={ecChart}
                                    color="rgb(255,88,122)"
                                    target={targetEC}
                                    ref={ecRef}
                                    refGroup={[ecRef, phRef, wtRef]}
                                />
                            </div>
                            <div>
                                <LineChart
                                    title="pH"
                                    unit={range <= 1 ? 'hour' : 'day'}
                                    step={range === 1 ? 4 : 1}
                                    data={phChart}
                                    color="rgb(127,226,254)"
                                    target={targetpH}
                                    ref={phRef}
                                    refGroup={[ecRef, phRef, wtRef]}
                                />
                            </div>
                        </div>
                        <div>
                            <LineChart
                                title="수온"
                                unit={range <= 1 ? 'hour' : 'day'}
                                step={range === 1 ? 4 : 1}
                                data={waterTempChart}
                                color="rgb(143,213,134)"
                                ref={wtRef}
                                refGroup={[ecRef, phRef, wtRef]}
                            />
                        </div>
                    </div>
                )}
        </div>
    );
};

export default Nutrient;
