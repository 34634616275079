import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Wifi, WifiOff } from '@mui/icons-material';
import axios from 'axios';
import { Chart, Point } from 'chart.js';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { simplePrompt } from 'react-simple-dialogs';
import { Skeleton } from '@mui/material';
import SingleStatusCard from '../../components/StatusCard/SingleStatusCard';
import LineChart, { LineChartData } from '../../components/LineChart/LineChart';
import TextRadioButton, {
    RadioButtonData,
} from '../../components/RadioButton/TextRadioButton';
import Nutrient from '../../components/Nutrient/Nutrient';
import useSmartFarm from '../../hooks/useSmartFarm';
import { hourBefore, now, timeDifference } from '../../utils/datetime-util';
import {
    ecMean,
    phMean,
    waterTemperatureMean,
    targetECMean,
    targetPHMean,
} from '../../utils/data-util';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Loading/Error';
import {
    co2Range,
    ecRange,
    humidityRange,
    phRange,
    temperatureRange,
    waterTemperatureRange,
} from '../../consts/item-range';
import { Device } from '../../types/type';

const RangeSelectData: RadioButtonData[] = [
    {
        value: 0,
        name: '3시간',
    },
    {
        value: 1,
        name: '1일',
    },
    {
        value: 2,
        name: '3일',
    },
    {
        value: 3,
        name: '7일',
    },
];

/**
 * Range 별 시간을 담은 배열
 */
const rangeHour = [3, 24, 72, 168];

/**
 * 개별 스마트팜의 정보를 표시하기 위한 view
 * /:스마트팜ID
 */
const FarmInfo: React.FC = () => {
    const { farmId } = useParams();

    const chartRef = useRef(null);

    const [range, setRange] = useState<number>(2);

    /**
     * 스마트팜 상태의 차트를 저장하는 상태
     */
    const [tempChart, setTempChart] = useState<LineChartData[] | undefined>(
        undefined
    );
    const [humiChart, setHumiChart] = useState<LineChartData[] | undefined>(
        undefined
    );
    const [co2Chart, setCo2Chart] = useState<LineChartData[] | undefined>(
        undefined
    );

    const tempRef = React.useRef<
        Chart<'line', (number | Point | null)[], unknown> | undefined
    >();

    const humiRef = React.useRef<
        Chart<'line', (number | Point | null)[], unknown> | undefined
    >();

    const co2Ref = React.useRef<
        Chart<'line', (number | Point | null)[], unknown> | undefined
    >();

    /**
     * 차트 로딩 여부
     */
    const [loadChart, setLoadChart] = useState<boolean>(false);

    const navigate = useNavigate();

    /**
     * 리스트로 돌아가기
     */
    const backToListHandler = useCallback(() => {
        navigate('/');
    }, [navigate]);

    /**
     * 양액기 사이트 진입
     * @param deviceId
     */
    const linkHandler = (deviceId: string): void => {
        window.open(`https://${deviceId}-tunnel.sangsang.dev`, '_blank');
    };

    const [smartFarm, loading, error] = useSmartFarm(Number(farmId));

    /**
     * 현재 장치가 값을 가져올수 있는 양액기인지 확인하는 함수
     */
    const isFeedmaker = (device: Device): boolean => {
        return (
            (device.sku === 'IG100' || device.sku === 'IG300') &&
            (device.power ?? false)
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = smartFarm?.name ?? '상상텃밭 IOT 모니터링';
    }, [smartFarm]);

    /**
     * 차트 로딩
     */
    useEffect(() => {
        const fetchChart = async (): Promise<void> => {
            setLoadChart(true);
            setCo2Chart(undefined);
            setHumiChart(undefined);
            setTempChart(undefined);

            const [temperatureHumidity, co2] = await Promise.all([
                axios
                    .get(
                        `${process.env.REACT_APP_API_URL}/smartfarmtemperaturehumidity/chart?smartFarmID=${farmId}&fromTime=${hourBefore(rangeHour[range])}&toTime=${now()}`
                    )
                    .then((res) => res.data)
                    .catch(() => undefined),
                axios
                    .get(
                        `${process.env.REACT_APP_API_URL}/smartfarmco2/chart?smartFarmID=${farmId}&fromTime=${hourBefore(rangeHour[range])}&toTime=${now()}`
                    )
                    .then((res) => res.data)
                    .catch(() => undefined),
            ]);

            if (temperatureHumidity !== undefined) {
                setTempChart(
                    temperatureHumidity.map(
                        (data: any): LineChartData => ({
                            measuredAt: data.time,
                            data: data.temperature,
                        })
                    )
                );
                setHumiChart(
                    temperatureHumidity.map(
                        (data: any): LineChartData => ({
                            measuredAt: data.time,
                            data: data.humidity,
                        })
                    )
                );
            } else {
                setTempChart(undefined);
                setHumiChart(undefined);
            }

            if (co2 !== undefined) {
                setCo2Chart(
                    co2.map(
                        (data: any): LineChartData => ({
                            measuredAt: data.time,
                            data: data.co2,
                        })
                    )
                );
            } else {
                setCo2Chart(undefined);
            }

            setLoadChart(false);
        };
        fetchChart();
    }, [range, farmId]);

    const createXlsxFile = async (filename?: string): Promise<void> => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        // Define columns
        worksheet.columns = [
            { header: '측정 시각', key: 'm', width: 30 },
            { header: '기온', key: 'temp', width: 10 },
            { header: '습도', key: 'humi', width: 10 },
            { header: '양액기 측정 시각', key: 'feed_m', width: 30 },
            { header: 'ec', key: 'ec', width: 10 },
            { header: 'pH', key: 'ph', width: 10 },
            { header: '수온', key: 'water_temp', width: 10 },
            { header: 'co2 측정 시각', key: 'co2_m', width: 30 },
            { header: 'co2', key: 'co2', width: 10 },
        ];

        const ec: LineChartData[] = JSON.parse(
            localStorage.getItem('ecChart')!
        );
        const ph: LineChartData[] = JSON.parse(
            localStorage.getItem('phChart')!
        );
        const waterTemp: LineChartData[] = JSON.parse(
            localStorage.getItem('waterTempChart')!
        );

        const maxLen = Math.max(
            tempChart?.length || 0,
            humiChart?.length || 0,
            co2Chart?.length || 0,
            ec.length,
            ph.length,
            waterTemp.length
        );

        for (let i = 0; i < maxLen; i += 1) {
            let row = {};
            if (tempChart && i < tempChart.length) {
                row = {
                    ...row,
                    m: dayjs(tempChart[i].measuredAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                    ),
                    temp: tempChart[i].data,
                };
            }
            if (humiChart && i < humiChart.length) {
                row = {
                    ...row,
                    humi: humiChart[i].data,
                };
            }
            if (co2Chart && i < co2Chart.length) {
                row = {
                    ...row,
                    co2_m: dayjs(co2Chart[i].measuredAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                    ),
                    co2: co2Chart[i].data,
                };
            }
            if (i < ec.length) {
                row = {
                    ...row,
                    feed_m: dayjs(ec[i].measuredAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                    ),
                    ec: ec[i].data,
                };
            }
            if (i < ph.length) {
                row = {
                    ...row,
                    ph: ph[i].data,
                };
            }
            if (i < waterTemp.length) {
                row = {
                    ...row,
                    water_temp: waterTemp[i].data,
                };
            }
            worksheet.addRow(row);
        }

        worksheet.mergeCells('K2:O3');
        worksheet.getCell('K2').value = '측정 그래프';
        worksheet.getCell('K2').alignment = { horizontal: 'center' };
        worksheet.getCell('K2').font = { bold: true, size: 20 };

        worksheet.mergeCells('Q2:T3');
        worksheet.getCell('Q2').value = `${RangeSelectData[range].name} 기준`;
        worksheet.getCell('Q2').alignment = { horizontal: 'center' };
        worksheet.getCell('Q2').font = { bold: true, size: 20 };

        const canvas = await html2canvas(chartRef.current!, { scale: 2 });
        const dataUrl = canvas.toDataURL();

        const imageId = workbook.addImage({
            base64: dataUrl.split(',')[1],
            extension: 'png',
        });

        // Add image to worksheet
        worksheet.addImage(imageId, 'K4:AN52'); // Adjust cell range as needed

        // Write to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        let downloadName = '';
        if (filename === undefined || filename === '') {
            downloadName = `${smartFarm?.name}.xlsx`;
        } else {
            downloadName = `${filename}.xlsx`;
        }

        saveAs(blob, downloadName);
    };

    if (loading < 100) {
        return <Loading loading={loading} />;
    }

    if (error !== '' || smartFarm === undefined) {
        return <Error />;
    }

    return (
        <main className="flex-1 select-none">
            <section
                className="mb-10 flex cursor-pointer items-center gap-4"
                onClick={(): void => {
                    backToListHandler();
                }}
            >
                <div className="flex text-[24px]">
                    <ArrowBack fontSize="inherit" />
                </div>
                <p className="text-2xl font-bold">리스트로 돌아가기</p>
            </section>
            <section className="flex gap-4">
                <div className="w-[100%] space-y-2 rounded-sm border-[1px] border-grayscale-600 bg-grayscale-200 p-4 shadow-around xl:w-[800px]">
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-bold lg:text-2xl">
                            {smartFarm.name}
                        </p>
                    </div>
                    <div
                        className="text-primary text-bold cursor-pointer text-lg font-bold"
                        onClick={(): void => {
                            simplePrompt('파일 이름을 입력해주세요').then(
                                (value) => {
                                    if (value === null) return;
                                    createXlsxFile(value);
                                }
                            );
                        }}
                    >
                        차트 다운로드
                    </div>
                    <div className="mt-4 flex gap-10">
                        <div className="flex">
                            <p>{timeDifference(smartFarm.updatedAt)}일&nbsp;</p>
                            {smartFarm.connection ? (
                                <p>연결 됨</p>
                            ) : (
                                <p>연결 끊김</p>
                            )}
                        </div>
                        {smartFarm.modeChanged !== undefined ? (
                            <p>
                                {smartFarm.mode}로 {smartFarm.modeChanged}일
                                작동중
                            </p>
                        ) : (
                            <p>현재 작동모드 알수 없음.</p>
                        )}
                    </div>
                </div>
            </section>
            <section className="mt-4 grid grid-cols-1 gap-4 lg:flex">
                {smartFarm.devices.map((device) => {
                    return (
                        <div
                            className="shadow-aroundp-2 flex items-center justify-start gap-4 rounded-sm border-[1px] border-grayscale-500 bg-grayscale-200 p-2 shadow-around"
                            onClick={(): void => {
                                if (isFeedmaker(device)) {
                                    linkHandler(device.id);
                                }
                            }}
                            style={{
                                cursor: isFeedmaker(device)
                                    ? 'pointer'
                                    : 'default',
                            }}
                            key={device.id}
                        >
                            <p className="text-md flex items-center font-bold">
                                {device.name} {isFeedmaker(device) && '- 링크'}
                            </p>
                            {device.connection ? (
                                <Wifi />
                            ) : (
                                <WifiOff className="text-fault" />
                            )}
                        </div>
                    );
                })}
            </section>
            <section className="mt-4 grid grid-cols-3 gap-4 md:flex md:flex-wrap">
                <div className="flex h-[90px] w-[110px]">
                    <SingleStatusCard
                        title="기온"
                        value={smartFarm.temperature}
                        scale="℃"
                        range={temperatureRange}
                        measuredAt={smartFarm.updated.temperature}
                    />
                </div>
                <div className="flex h-[90px] w-[110px]">
                    <SingleStatusCard
                        title="습도"
                        scale="%"
                        range={humidityRange}
                        value={smartFarm.humidity}
                        measuredAt={smartFarm.updated.humidity}
                    />
                </div>
                <div className="flex h-[90px] w-[110px]">
                    <SingleStatusCard
                        title="co2"
                        value={smartFarm.co2}
                        range={co2Range}
                        measuredAt={smartFarm.updated.co2}
                    />
                </div>
                <div className="flex h-[90px] w-[110px]">
                    <SingleStatusCard
                        title="ec"
                        value={ecMean(smartFarm.devices)}
                        range={ecRange}
                        target={targetECMean(smartFarm.devices)}
                    />
                </div>
                <div className="flex h-[90px] w-[110px]">
                    <SingleStatusCard
                        title="pH"
                        value={phMean(smartFarm.devices)}
                        range={phRange}
                        target={targetPHMean(smartFarm.devices)}
                    />
                </div>
                <div className="flex h-[90px] w-[110px]">
                    <SingleStatusCard
                        title="수온"
                        scale="℃"
                        value={waterTemperatureMean(smartFarm.devices)}
                        range={waterTemperatureRange}
                    />
                </div>
            </section>
            <section className="flex gap-4 text-xl text-[#ADB5BD]">
                <TextRadioButton
                    data={RangeSelectData}
                    currentValue={range}
                    radioName="rangeRadio"
                    setValue={setRange}
                />
            </section>
            <div ref={chartRef}>
                {loadChart && (
                    <section className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
                        <div className="flex flex-col gap-4">
                            <Skeleton variant="rectangular" height={'192px'} />
                            <Skeleton variant="rectangular" height={'192px'} />
                        </div>
                        <Skeleton variant="rectangular" height={'192px'} />
                    </section>
                )}
                {!loadChart &&
                    tempChart !== undefined &&
                    humiChart !== undefined &&
                    co2Chart !== undefined && (
                        <section className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col">
                                    <LineChart
                                        title="기온"
                                        unit={range <= 1 ? 'hour' : 'day'}
                                        step={range === 1 ? 4 : 1}
                                        data={tempChart}
                                        color="rgb(255,88,122)"
                                        ref={tempRef}
                                        refGroup={[tempRef, humiRef, co2Ref]}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <LineChart
                                        title="습도"
                                        unit={range <= 1 ? 'hour' : 'day'}
                                        step={range === 1 ? 4 : 1}
                                        data={humiChart}
                                        color="rgb(127,226,254)"
                                        ref={humiRef}
                                        refGroup={[tempRef, humiRef, co2Ref]}
                                    />
                                </div>
                            </div>
                            <div>
                                <LineChart
                                    title="co2"
                                    unit={range <= 0 ? 'hour' : 'day'}
                                    step={range === 1 ? 4 : 1}
                                    data={co2Chart}
                                    color="rgb(143,213,134)"
                                    ref={co2Ref}
                                    refGroup={[tempRef, humiRef, co2Ref]}
                                />
                            </div>
                        </section>
                    )}
                <section className="mt-5 flex flex-col gap-4">
                    {smartFarm.devices
                        .filter((device) => {
                            return isFeedmaker(device);
                        })
                        .map((device) => {
                            return (
                                <Nutrient
                                    key={device.id}
                                    name={device.name}
                                    range={range}
                                    deviceId={device.id}
                                    connected={device.connection}
                                    targetEC={targetECMean(smartFarm.devices)}
                                    targetpH={targetPHMean(smartFarm.devices)}
                                />
                            );
                        })}
                </section>
            </div>
        </main>
    );
};

export default FarmInfo;
