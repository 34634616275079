import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SidebarListItem from './SidebarListItem';
import { SmartFarm } from '../../types/type';
import Loading from '../Loading/Loading';
import Error from '../Loading/Error';
import { retrieveFavorite } from '../../utils/favorite-util';

type SidebarProp = {
    open: boolean;
    farms: SmartFarm[];
    loading: number;
    error: string;
};

/**
 * 사이드바 컴포넌트입니다.
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.open - 사이드바가 열려있는지 여부.
 * @param {Array} props.farms - 사이드바에 표시할 농장 목록.
 * @param {number} props.loading - 농장 목록의 로딩 상태. ( 0 - 시작, 100 - 끝 )
 * @param {boolean} props.error - 로딩중 에러 발생시 에러 메시지.
 * @returns {JSX.Element} 사이드바 컴포넌트
 */
const Sidebar: React.FC<SidebarProp> = ({ open, farms, loading, error }) => {
    const [openSidebar, setOpenSidebar] = useState(open);

    const toggleDrawer = (): void => {
        setOpenSidebar(!openSidebar);
    };

    const isMobile = useMediaQuery('(max-width: 768px)');

    const [, refreshSidebar] = useState(false);

    return (
        <>
            <aside
                className="z-10 h-[65px] cursor-pointer rounded-br-md pb-[10px] pl-[15px] pr-[20px] text-[40px] text-[#151515] lg:sticky lg:left-0 lg:top-0"
                onClick={(): void => setOpenSidebar((prev) => !prev)}
                data-testid="sidebar-closed"
            >
                <MenuIcon className="" fontSize="inherit" />
            </aside>
            <Drawer
                onClose={toggleDrawer}
                open={openSidebar}
                anchor={`${isMobile ? 'top' : 'left'}`}
                classes={{
                    paper: 'scrollbar-thin scrollbar-thumb-[#212529] scrollbar-track-[#343A40] overscroll-none scrollbar-w-[2px]',
                }}
            >
                <section className="flex flex-col gap-4 bg-[#343A40] px-5 pb-10 pt-5">
                    {isMobile && (
                        <section
                            className="cursor-pointer text-[#F8F9FA]"
                            onClick={toggleDrawer}
                        >
                            <ArrowBackIcon fontSize="large" color="inherit" />
                        </section>
                    )}
                    {loading < 100 && (
                        <Loading loading={loading} theme="dark" />
                    )}
                    {error && <Error />}
                    {farms && (
                        <>
                            {farms
                                .filter((farm) =>
                                    retrieveFavorite().includes(farm.id)
                                )
                                .map((farm) => {
                                    return (
                                        <SidebarListItem
                                            setSidebar={setOpenSidebar}
                                            farmID={farm.id}
                                            key={farm.id}
                                            title={farm.name}
                                            status={farm.connection}
                                            recent={
                                                (farm.modeChanged ?? 100) <= 3
                                            }
                                            mode={farm.mode}
                                            modeChanged={farm.modeChanged}
                                            favorite={true}
                                            refreshSidebar={refreshSidebar}
                                        />
                                    );
                                })}
                        </>
                    )}
                    {farms &&
                        farms
                            .filter(
                                (farm) => !retrieveFavorite().includes(farm.id)
                            )
                            .map((farm) => {
                                return (
                                    <SidebarListItem
                                        setSidebar={setOpenSidebar}
                                        farmID={farm.id}
                                        key={farm.id}
                                        title={farm.name}
                                        status={farm.connection}
                                        recent={(farm.modeChanged ?? 100) <= 3}
                                        mode={farm.mode}
                                        modeChanged={farm.modeChanged}
                                        favorite={false}
                                        refreshSidebar={refreshSidebar}
                                    />
                                );
                            })}
                </section>
            </Drawer>
        </>
    );
};

export default Sidebar;
