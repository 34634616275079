/**
 * 온도와 같은 단일 정보를 표시하기 위한 카드입니다.
 */

import React, { useRef } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { RangeType } from '../../consts/item-range';
import { round } from '../../utils/data-util';

type SingleStatusCardProp = {
    title: string;
    scale?: string;
    value?: number;
    range: RangeType[];
    measuredAt?: string;
    target?: number;
};

const backgroundColor = (
    value: number | undefined,
    range: RangeType[]
): string => {
    if (value === undefined) return '#073B4C';
    if (range.length === 0) return '#F8F9FA';
    for (let i = 0; i < range.length; i += 1) {
        if (value > range[i].range[0] && value <= range[i].range[1]) {
            return range[i].color;
        }
    }
    return '#073B4C';
};

/**
 * 단일 상태를 표기하기 위한 카드 컴포넌트입니다.
 *
 * @component
 * @param {SingleStatusCardProp} props
 * @param {string} props.title - 상태 카드의 제목.
 * @param {string} [props.scale=''] - 상태 카드의 단위.
 * @param {number} props.value - 상태 카드의 값.
 * @param {number[]} props.range - 값이 허용되는 범위.
 * @param {string} props.measuredAt - 값을 측정한 시간.
 * @param {number} props.target - 목표값.
 * @returns {JSX.Element} 단일 상태 카드 컴포넌트
 */
const SingleStatusCard: React.FC<SingleStatusCardProp> = ({
    title,
    scale = '',
    value,
    range,
    measuredAt,
    target,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const date = new Date(measuredAt ?? '');

    return (
        <div
            className={
                'relative z-[-5] flex flex-1  flex-col justify-between overflow-hidden rounded-sm border-[1px] border-grayscale-700 p-1 px-[5px] shadow-around'
            }
            style={{
                backgroundColor: backgroundColor(value, range),
            }}
            ref={ref}
            title={`${measuredAt ? date.toLocaleString('ko') : '알수없음'} 에 측정됨.`}
            data-testid="single-status-card"
        >
            <section>
                <p
                    className="text-xl font-medium"
                    style={{ color: value === undefined ? '#F8F9FA' : 'black' }}
                >
                    {title}
                </p>
            </section>
            {value === undefined && (
                <section className="absolute right-[10px] text-2xl">
                    <ErrorOutlineOutlinedIcon
                        className="text-[#F8F9FA]"
                        fontSize="inherit"
                    />
                </section>
            )}
            <section className="self-end">
                <p
                    className="text-lg font-bold lg:text-2xl"
                    style={{ color: value === undefined ? '#F8F9FA' : 'black' }}
                >
                    {value === undefined
                        ? '측정안됨'
                        : `${round(value, 1).toFixed(1).replace(/\.0$/, '')} ${scale}`}
                </p>
                {target && (
                    <p
                        style={{
                            color: value === undefined ? '#F8F9FA' : 'black',
                        }}
                    >
                        {target &&
                            `/ ${round(target, 1).toFixed(1).replace('.0', '')}`}
                    </p>
                )}
            </section>
        </div>
    );
};

export default SingleStatusCard;
