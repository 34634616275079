import React from 'react';
import { SimpleDialogContainer } from 'react-simple-dialogs';
import Main from './views/Main/Main';

const App = (): React.JSX.Element => (
    <main className="h-[100%] font-nanumSquare">
        <Main />
        <SimpleDialogContainer />
    </main>
);

export default App;
