import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { LinearProgress } from '@mui/material';

type LoadingProps = {
    loading: number;
    theme?: 'dark' | 'light';
};

/**
 * 데이터 로딩중일때 나오는 컴포넌트
 * @returns
 */
const Loading: React.FC<LoadingProps> = ({ loading, theme = 'light' }) => {
    return (
        <main
            className={`flex flex-1 flex-col items-start justify-start gap-4 text-4xl ${theme === 'light' ? 'text-[#212529]' : 'text-[#F8F9FA]'}`}
        >
            <AutorenewIcon fontSize="inherit" className="animate-spin" />
            <div className="text-3xl">데이터 로딩중</div>
            <div className="w-[100%] space-y-2 md:w-[50%]">
                <LinearProgress variant="determinate" value={loading} />
                <div className="text-lg">{loading.toFixed(1)}%</div>
            </div>
        </main>
    );
};

export default Loading;
