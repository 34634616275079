import { RefObject, forwardRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    TimeScale,
    ChartData,
    Chart,
    Point,
    Plugin,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { ChartTimeUnit } from '../../types/chart-type';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

/**
 * 차트에 들어갈 데이터 타입
 * @type measuredAt: ISO 8601 시각 문자열
 * @type data: 데이터
 */
export type LineChartData = {
    measuredAt: string;
    data: number;
};

/** y축의 최대/최소 값을 계산하는 함수 2개 */
const getSuggestedMin = (title: string, minimum: number): number => {
    switch (title) {
        case 'ec':
            return 0;
        case 'pH':
            return 5;
        case '수온':
            return 0;
        default:
            return minimum;
    }
};

const getSuggestedMax = (
    title: string,
    maximum: number,
    target: number = 0
): number => {
    switch (title) {
        case 'ec':
            return target + 500;
        case 'pH':
            return 7.5;
        case '수온':
            return maximum + 5;
        default:
            return maximum;
    }
};

/**
 * 주어진 ISO 8601 형식의 날짜 문자열을 포맷팅합니다.
 * @param date 대상 날짜 문자add a
 * @param unit 포맷팅 단위. 'day' 또는 'hour' 중 하나를 사용할 수 있습니다.
 * @returns 한글 날짜로 포맷팅된 문자열.
 * @example formatter('2021-10-01T00:00:00.000Z', 'hour') // '10/1 0시'
 */
const formatter = (date: string, unit: ChartTimeUnit): string => {
    const d = new Date(date);

    const formattedDate =
        unit === 'day'
            ? `${d.getMonth() + 1}/${d.getDate()}`
            : `${d.getMonth() + 1}/${d.getDate()} ${d.getHours()}시`;

    return formattedDate;
};

/**
 * 데이터의 갯수를 720개로 맞추기 위한 함수입니다.
 * @param data
 * @returns
 */
const dataCompressor = (
    data: LineChartData[],
    maximumCount: number
): LineChartData[] => {
    // 데이터가 너무 많을 경우 ( > 720 ), 최대 720으로 길이를 조정하기 위해 값을 건너 뜁니다.
    if (data.length > maximumCount) {
        const skipRate = Math.ceil(data.length / 720);
        return data.filter((_, index) => index % skipRate === 0);
    }
    return data;
};

type LineChartProp = {
    title: string;
    data?: LineChartData[];
    unit?: ChartTimeUnit;
    color?: string;
    step?: number;
    target?: number;
    refGroup?: RefObject<
        Chart<'line', (number | Point | null)[], unknown> | undefined
    >[];
};

/**
 * 주어진 데이터에 대한 라인 차트를 제공하는 컴포넌트입니다.
 *
 * @component
 * @param {string} title - 차트 제목
 * @param {LineChartData} [data] - 차트에 들어갈 데이터, measuredAt은 시간, data는 값입니다.
 * @param {string} [unit='day'] - x축의 단위를 지정합니다. 'day' 또는 'hour' 중 하나를 사용할 수 있습니다.
 * @param {string} [color='blue'] - 라인차트의 색상을 지정합니다.
 * @param {number} [step=1] - x축의 표시 간격을 지정합니다.
 * @param {ref[]} refgroup - 이 차트와 연동되는 차트들과 본 차트의 ref 배열입니다.
 * @param {ref} ref - useRef 와 차트를 연결합니다.
 * @returns {JSX.Element} 차트 컴포넌트
 */
const LineChart = forwardRef<
    Chart<'line', (number | Point | null)[], unknown> | undefined,
    LineChartProp
>(
    (
        {
            title,
            data = undefined,
            unit = 'day',
            color = 'blue',
            step = 1,
            target = 0,
            refGroup,
        },
        ref
    ) => {
        if (data === undefined) {
            return (
                <div className="flex-1 p-2 lg:p-4">
                    <div className="mb-2 lg:mb-4">
                        <p className="text-lg font-bold lg:text-2xl">{title}</p>
                    </div>
                    <div className="text-center">
                        <p className="text-lg font-medium">
                            차트 데이터가 없거나 로딩중입니다.
                        </p>
                    </div>
                </div>
            );
        }

        const lessData: LineChartData[] = dataCompressor(data, 720);

        const labels = lessData.map((item) => item.measuredAt);

        const chartData: ChartData<'line', number[]> = {
            labels,
            datasets: [
                {
                    label: title,
                    data: lessData.map((item) => item.data),
                    borderColor: color,
                    pointStyle: false as const,
                    borderJoinStyle: 'round' as const,
                    borderWidth: 2,
                    borderCapStyle: 'round' as const,
                    cubicInterpolationMode: 'monotone' as const,
                },
            ],
        };

        /**
         * 툴팁에 해당하는 세로선을 긋기 위한 플러그인,
         * 툴팁 활성화시 context를 통해 선을 긋는다.
         * lineWidth로 굵기를, strokeStyle로 색상을 지정할수 있다.
         */
        const hoverLine: Plugin = {
            id: 'hoverLine',
            afterDatasetsDraw(chart) {
                const {
                    ctx,
                    tooltip,
                    chartArea: { top, bottom },
                } = chart;

                if (tooltip?.caretX !== undefined && tooltip?.opacity !== 0) {
                    ctx.save();
                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = color;
                    ctx.moveTo(tooltip.caretX, top);
                    ctx.lineTo(tooltip.caretX, bottom);
                    ctx.stroke();
                    ctx.closePath();
                }
            },
        };

        /**
         * onHover 를 통해 refGroup 에 속한 다른 차트의 툴팁을 활성화 시킨다.
         */
        const options: ChartOptions<'line'> = {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                line: {
                    tension: 1,
                },
            },
            onHover: (event, chartElement, chart) => {
                if (chartElement.length > 0) {
                    const { datasetIndex } = chartElement[0];
                    let { index } = chartElement[0];
                    if (!refGroup) {
                        return;
                    }
                    refGroup.forEach((chartRef) => {
                        if (chartRef.current) {
                            if (
                                chart.data.datasets[0].label ===
                                chartRef.current.data.datasets[0].label
                            )
                                return;

                            if (
                                chartRef.current.data.datasets[0].data.length <=
                                index
                            ) {
                                index =
                                    chartRef.current.data.datasets[0].data
                                        .length - 1;
                            }

                            chartRef.current.tooltip?.setActiveElements(
                                [
                                    {
                                        datasetIndex,
                                        index,
                                    },
                                ],
                                { x: 1, y: 1 }
                            );

                            if (chartRef.current.tooltip?.opacity === 0) {
                                chartRef.current.update('active');
                            } else {
                                chartRef.current.draw();
                            }
                        }
                    });
                }
            },
            hover: {
                intersect: false,
                mode: 'index',
                axis: 'x',
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
                tooltip: {
                    intersect: false,
                    mode: 'index',
                    axis: 'x',
                    animation: {
                        duration: 0,
                    },
                },
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit,
                        tooltipFormat: 'yyyy-MM-dd HH시 mm분',
                    },
                    ticks: {
                        display: window.innerWidth > 768,
                        callback: (label) => {
                            return formatter(label as string, unit);
                        },
                        stepSize: step,
                    },
                },
                y: {
                    type: 'linear',
                    suggestedMin: getSuggestedMin(
                        title,
                        Math.min(...lessData.map((item) => item.data))
                    ),
                    suggestedMax: getSuggestedMax(
                        title,
                        Math.max(...lessData.map((item) => item.data)),
                        target
                    ),
                },
            },
            animation: {
                duration: 0,
            },
        };

        return (
            <div
                className="rounded-sm border-[1px] border-grayscale-500 p-2 shadow-around lg:p-4"
                onMouseLeave={(): void => {
                    refGroup!.forEach((chartRef) => {
                        if (chartRef.current) {
                            chartRef.current.tooltip?.setActiveElements([], {
                                x: 0,
                                y: 0,
                            });
                            chartRef.current.update('active');
                        }
                    });
                }}
                onBlur={(): void => {
                    refGroup!.forEach((chartRef) => {
                        if (chartRef.current) {
                            chartRef.current.tooltip?.setActiveElements([], {
                                x: 0,
                                y: 0,
                            });
                            chartRef.current.update('active');
                        }
                    });
                }}
            >
                <div className="mb-1 flex items-center gap-4 lg:mb-4">
                    <p className="text-lg font-extrabold lg:text-2xl">
                        {title}
                    </p>
                </div>
                <div>
                    <Line
                        options={options}
                        data={chartData}
                        ref={ref}
                        plugins={[hoverLine]}
                    />
                </div>
            </div>
        );
    }
);

export default LineChart;
