import { useEffect, useState } from 'react';
import axios from 'axios';
import { Device, DeviceBackend, SmartFarm } from '../types/type';
import {
    currentDeviceMode,
    currentDeviceModeChanged,
    currentDevicePriority,
} from '../utils/data-util';

type UseSmartFarm = [SmartFarm | undefined, number, string, () => void];

/**
 * 스마트팜의 데이터를 가져오는 커스텀 훅입니다.
 * @param id - 가져올 스마트팜의 ID
 * @returns 스마트팜 데이터, 로딩 상태, 에러, 리프레시 핸들러의 배열.
 * @example const [smartFarm, loading, error, refreshHandler] = useSmartFarm(1);
 */
const useSmartFarm = (id: number): UseSmartFarm => {
    const [smartFarm, setSmartFarm] = useState<SmartFarm | undefined>(
        undefined
    );
    const [loading, setLoading] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [refresh, setRefresh] = useState<boolean>(false);

    const refreshHandler = (): void => {
        setRefresh((prev) => !prev);
    };

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            let farmData;

            try {
                farmData = await axios.get(
                    `${process.env.REACT_APP_API_URL}/smartfarm/${id}`
                );
            } catch {
                setError('데이터를 가져오는데 에러가 발생했습니다.');
                setLoading(100);
            }

            let farm: SmartFarm = {
                id: farmData?.data.id,
                name: farmData?.data.name,
                updatedAt: farmData?.data.updatedAt,
                mode: currentDeviceMode(farmData?.data.devices),
                modeChanged: currentDeviceModeChanged(farmData?.data.devices),
                priority: currentDevicePriority(farmData?.data.devices),
                updated: {},
                devices:
                    farmData?.data.devices &&
                    farmData?.data.devices.map(
                        (device: DeviceBackend): Device => {
                            return {
                                connection: device.connection,
                                id: device.id,
                                name: device.name,
                                ec: device.measurer?.power
                                    ? device.measurer?.ec
                                    : undefined,
                                ph: device.measurer?.power
                                    ? device.measurer?.ph
                                    : undefined,
                                waterTemperature: device.measurer?.power
                                    ? device.measurer?.waterTemperature
                                    : undefined,
                                targetEC: device.measurer?.power
                                    ? device.measurer?.targetEC
                                    : undefined,
                                targetPH: device.measurer?.power
                                    ? device.measurer?.targetPH
                                    : undefined,
                                sku: device.sku,
                                power: device.measurer?.power,
                            };
                        }
                    ),
                connection:
                    farmData?.data.devices.length !==
                    farmData?.data.devices.reduce(
                        (acc: number, cur: any) =>
                            acc + (cur.connection ? 0 : 1),
                        0
                    ),
            };

            setLoading(25);

            const [temperature, humidity, temperatureUpdated] = await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/smartfarmtemperaturehumidity/recent?smartFarmID=${farm.id}`
                )
                .then((res) => res.data)
                .then((data) => [
                    data.temperature,
                    data.humidity,
                    data.updatedAt,
                ])
                .catch(() => [undefined, undefined, undefined]);

            setLoading(50);

            const [co2, co2Updated] = await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/smartfarmco2/recent?smartFarmID=${farm.id}`
                )
                .then((res) => res.data)
                .then((data) => [data.co2, data.updatedAt])
                .catch(() => [undefined, undefined]);

            const updatedValue = {
                ...farm.updated,
                temperature: temperatureUpdated,
                humidity: temperatureUpdated,
                co2: co2Updated,
            };

            setLoading(75);

            farm = {
                ...farm,
                temperature,
                humidity,
                co2,
                updated: updatedValue,
            };

            setSmartFarm(farm);
            setLoading(100);
        };

        fetchData();
    }, [refresh, id]);

    return [smartFarm, loading, error, refreshHandler];
};

export default useSmartFarm;
