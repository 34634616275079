import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';

/**
 * 데이터 로딩이 실패했을때 나오는 컴포넌트
 */
const Error: React.FC = () => {
    return (
        <main className="flex h-screen flex-1 items-start justify-center text-4xl">
            <ErrorIcon fontSize="inherit" className="text-fault" />
            <p className="ml-4">데이터 로딩중 에러발생!</p>
        </main>
    );
};

export default Error;
