import { differenceInDays } from 'date-fns';
import { Device, DeviceBackend, farmMode } from '../types/type';

/**
 * 기기 평균값과 같은 값을 계산하는 함수들의 모음
 */

/**
 * 스마트팜 내 양액기들의 평균 EC값을 구하는 함수
 * @param devices
 * @returns
 */
export const ecMean = (devices: Device[]): number | undefined => {
    let sum = 0;
    let length = 0;

    devices.forEach((device) => {
        if (device.ec) {
            sum += device.ec;
            length += 1;
        }
    });
    if (length === 0) {
        return undefined;
    }
    return sum / length;
};

/**
 * 스마트팜 내 수온의 평균을 구하는 함수
 * @param devices
 * @returns
 */
export const waterTemperatureMean = (devices: Device[]): number | undefined => {
    let sum = 0;
    let length = 0;

    devices.forEach((device) => {
        if (device.waterTemperature) {
            sum += device.waterTemperature;
            length += 1;
        }
    });
    if (length === 0) {
        return undefined;
    }
    return sum / length;
};

/**
 * 스마트팜 내 양액기들의 평균 pH값을 구하는 함수
 * @param devices
 * @returns
 */
export const phMean = (devices: Device[]): number | undefined => {
    let sum = 0;
    let length = 0;

    devices.forEach((device) => {
        if (device.ph) {
            sum += device.ph;
            length += 1;
        }
    });
    if (length === 0) {
        return undefined;
    }
    return sum / length;
};

/**
 * 장치들의 목표 EC값의 평균을 구하는 함수
 * @param devices
 * @returns
 */
export const targetECMean = (devices: Device[]): number | undefined => {
    let sum = 0;
    let length = 0;

    devices.forEach((device) => {
        if (device.targetEC) {
            sum += device.targetEC;
            length += 1;
        }
    });
    if (length === 0) {
        return undefined;
    }
    return sum / length;
};

/**
 * 장치들의 목표 pH값의 평균을 구하는 함수
 * @param devices
 * @returns
 */
export const targetPHMean = (devices: Device[]): number | undefined => {
    let sum = 0;
    let length = 0;

    devices.forEach((device) => {
        if (device.targetPH) {
            sum += device.targetPH;
            length += 1;
        }
    });
    if (length === 0) {
        return undefined;
    }
    return sum / length;
};

/**
 * 장치들에서 현재 모드를 얻어내는 함수
 */
export const currentDeviceMode = (
    devices?: DeviceBackend[]
): string | undefined => {
    if (devices === undefined) return undefined;
    for (let i = 0; i < devices.length; i += 1) {
        if (devices[i].feedMaker !== null && devices[i].feedMaker.mode !== null)
            return farmMode[devices[i].feedMaker.mode][0];
    }
    return undefined;
};

/**
 * 장치들에서 현재 모드의 우선순위를 얻어내는 함수
 */
export const currentDevicePriority = (devices?: DeviceBackend[]): number => {
    if (devices === undefined) return 99;
    for (let i = 0; i < devices.length; i += 1) {
        if (devices[i].feedMaker !== null && devices[i].feedMaker.mode !== null)
            return farmMode[devices[i].feedMaker.mode][1];
    }
    return 99;
};

/**
 * 장치들에서 현재 모드가 바뀐 시간을 일자로 얻어내는 함수
 */
export const currentDeviceModeChanged = (
    devices?: DeviceBackend[]
): number | undefined => {
    if (devices === undefined) return undefined;
    for (let i = 0; i < devices.length; i += 1) {
        if (
            devices[i].feedMaker !== null &&
            devices[i].feedMaker.modeChangedTime !== null
        ) {
            const d = new Date(Number(devices[i].feedMaker.modeChangedTime));
            return differenceInDays(new Date(), d);
        }
    }
    return undefined;
};

export const round = (value: number, precision: number): number => {
    const multiplier = 10 ** precision;
    return Math.round(value * multiplier) / multiplier;
};
