import React, { useId, ChangeEvent } from 'react';

export type RadioButtonData = {
    value: number;
    name: string;
};

type TextRadioButtonProp = {
    data: RadioButtonData[];
    radioName: string;
    currentValue: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * 문자열 옵션을 표시하기 위한 라디오 버튼 컴포넌트입니다.
 *
 * @component
 * @param {TextRadioButtonProp} props
 * @param {Array<RadioButtonItem>} props.data - 라디오버튼에 표시할 데이터와 값.
 * @param {string} props.currentValue - 현재 선택된 값.
 * @param {Function} props.setValue - 값을 선택하기 위한 함수.
 * @returns {JSX.Element} 문자열 라디오 버튼 컴포넌트
 */
const TextRadioButton: React.FC<TextRadioButtonProp> = ({
    data,
    currentValue,
    setValue,
}) => {
    const chosenStyle =
        'border-b-2 font-bold text-[black] border-grayscale-900';
    const id = useId();

    const changeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(+e.target.value);
    };

    return (
        <section className="mt-5 flex gap-4 text-xl text-[#ADB5BD]">
            {data.map((buttonItem) => (
                <div key={buttonItem.value}>
                    <label
                        htmlFor={buttonItem.name}
                        className={`${buttonItem.value === currentValue && chosenStyle} cursor-pointer transition duration-100 ease-in`}
                    >
                        {buttonItem.name}
                    </label>
                    <input
                        type="radio"
                        name={id}
                        id={buttonItem.name}
                        value={buttonItem.value}
                        checked={buttonItem.value === currentValue}
                        onChange={changeHandler}
                        className="appearance-none"
                    />
                </div>
            ))}
        </section>
    );
};
export default TextRadioButton;
