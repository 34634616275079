/**
 * 장치를 가져올때 백엔드의 타입
 */
export type DeviceBackend = {
    id: string;
    name: string;
    connection: boolean;
    updatedAt: string;
    sku: string;
    measurer?: {
        ec?: number;
        ph?: number;
        waterTemperature?: number;
        targetEC?: number;
        targetPH?: number;
        power?: boolean;
    };
    feedMaker: {
        mode: string;
        modeChangedTime: string;
    };
};

/**
 * 마지막으로 업데이트 된 시간을 기록하는 타입
 */
type UpdatedType = {
    temperature?: string;
    humidity?: string;
    co2?: string;
    ec?: string;
    ph?: string;
    waterTemperature?: string;
};

/**
 * 내부적으로 사용하는 장치 타입
 */
export type Device = {
    id: string;
    name: string;
    connection: boolean;
    ec?: number;
    ph?: number;
    targetEC?: number;
    targetPH?: number;
    waterTemperature?: number;
    sku: string;
    power?: boolean;
};

/**
 * 내부적으로 사용하는 스마트팜 타입
 */
export type SmartFarm = {
    id: number;
    name: string;
    devices: Device[];
    updatedAt: string;
    temperature?: number;
    humidity?: number;
    co2?: number;
    connection: boolean;
    mode?: string;
    modeChanged?: number;
    priority: number;
    updated: UpdatedType;
};

/** 재배 모드 */
// 두번째 인자는 우선순위, 낮을수록 리스트에서 상단에 보인다.
export const farmMode: Record<string, [string, number]> = {
    WAIT: ['대기 모드', 99],
    STOP: ['중지 모드', 99],
    PRE_CULTIVATE: ['재배 준비 모드', 2],
    CULTIVATE: ['재배 모드', 0],
    CLEAN: ['청소 모드', 99],
    RINSE: ['헹굼 모드', 99],
    WASTE: ['폐수 모드', 99],
};
