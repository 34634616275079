import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import FarmList from '../FarmList/FarmList';
import FarmInfo from '../FarmInfo/FarmInfo';
import useSmartFarms from '../../hooks/useSmartFarms';

/**
 * 라우팅에 따라 목록 혹은 단일 뷰를 보여주고, 사이드바를 관리하는 view
 * @returns 메인 컴포넌트
 */
const Main: React.FC = () => {
    const [smartFarms, loading, error, refreshHandler] = useSmartFarms();

    return (
        <div className="flex flex-1 flex-col lg:flex-row">
            <Sidebar
                farms={smartFarms}
                loading={loading}
                error={error}
                open={false}
            />
            <div className="mx-3 mb-3 flex flex-1 lg:m-10">
                {
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <FarmList
                                    smartFarms={smartFarms}
                                    loading={loading}
                                    error={error}
                                    refreshHandler={refreshHandler}
                                />
                            }
                        />
                        <Route path="/:farmId" element={<FarmInfo />} />
                    </Routes>
                }
            </div>
        </div>
    );
};

export default Main;
