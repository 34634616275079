import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Star, StarOutlineOutlined, Wifi, WifiOff } from '@mui/icons-material';
import {
    ecRange,
    humidityRange,
    phRange,
    temperatureRange,
} from '../../consts/item-range';
import SmallSingleStatusCard from '../StatusCard/SmallSingleStatusCard';
import { addFavorite, removeFavorite } from '../../utils/favorite-util';

/**
 * smartfarmId : 스마트팜 아이디
 * ec: 전기전도도
 * humidity: 습도
 * ph: 수소농도
 * temperature: 기온
 * connected: 연결상태
 * mode: 현재모드
 * workingDay: 현재모드로 작동 시간
 */
type SmartFarmListItemProp = {
    name: string;
    smartFarmId: number;
    ec?: number;
    humidity?: number;
    ph?: number;
    temperature?: number;
    mode?: string;
    workingDay: number;
    connected: boolean;
    targetEC?: number;
    targetPH?: number;
    modeChanged?: number;
    favorite?: boolean;
    refreshList: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * 메인 화면에서 스마트팜 리스트의 아이템을 나타내는 컴포넌트입니다.
 * @param name - 스마트팜의 이름
 * @param smartFarmId - 스마트팜의 ID
 * @param ec - 스마트팜 내 양액기들의 EC 값 평균
 * @param humidity - 스마트팜 내 습도
 * @param ph - 스마트팜 내 양액기들의 pH 값 평균
 * @param temperature - 스마트팜의 기온
 * @param mode - 스마트팜 모드
 * @param workingDay - 스마트팜이 현재 상태로 작동한 날짜
 * @param connected - 스마트팜 연결 상태
 * @param targetEC - 스마트팜 양액기들의 target EC 평균 값
 * @param targetPH - 스마트팜 양액기들의 target pH 평균 값
 * @param modeChanged - 현재 모드로 스마트팜의 모드가 변경된 이후의 날짜
 */
const SmartFarmListItem: React.FC<SmartFarmListItemProp> = ({
    name,
    smartFarmId,
    ec,
    humidity,
    ph,
    temperature,
    mode,
    workingDay,
    connected,
    targetEC,
    targetPH,
    modeChanged,
    favorite,
    refreshList,
}) => {
    const navigate = useNavigate();

    const farmClickHandler = (): void => {
        navigate(`/${smartFarmId}`);
    };

    const favoriteHandler = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        if (favorite) {
            removeFavorite(smartFarmId);
        } else {
            addFavorite(smartFarmId);
        }
        refreshList((prev) => !prev);
    };

    return (
        <div
            className="group flex cursor-pointer select-none flex-col justify-between border-[1px] border-grayscale-500 bg-grayscale-100 shadow-around hover:animate-jump"
            data-testid="smart-farm-list-item"
            onClick={farmClickHandler}
        >
            <section className="flex justify-between px-2 pt-2">
                <div className="break-keep text-xl font-bold">{name}</div>
                <div
                    className={`flex text-2xl ${favorite ? 'text-[#ffe234]' : 'text-[black]'} cursor-pointer`}
                    onClick={favoriteHandler}
                >
                    {favorite ? (
                        <Star fontSize="inherit" />
                    ) : (
                        <StarOutlineOutlined fontSize="inherit" />
                    )}
                </div>
            </section>
            <section>
                <div
                    className={`m-2 grid grid-cols-2 gap-2 ${mode === '재배 모드' ? 'opacity-100' : 'opacity-50'} group-hover:opacity-100`}
                >
                    <div className="flex">
                        <SmallSingleStatusCard
                            title="ec"
                            value={ec}
                            range={ecRange}
                            target={targetEC}
                        />
                    </div>
                    <div className="flex">
                        <SmallSingleStatusCard
                            title="pH"
                            value={ph}
                            range={phRange}
                            target={targetPH}
                        />
                    </div>
                    <div className="flex">
                        <SmallSingleStatusCard
                            title="기온"
                            value={temperature}
                            range={temperatureRange}
                        />
                    </div>
                    <div className="flex">
                        <SmallSingleStatusCard
                            title="습도"
                            value={humidity}
                            range={humidityRange}
                        />
                    </div>
                </div>
            </section>
            <section className="bg-[#DEE2E6] shadow-around">
                <div className="flex items-center justify-between rounded-b-md px-3 py-2">
                    <div>
                        <p className="text-md font-bold">
                            {modeChanged !== undefined && mode}
                        </p>
                    </div>
                    <div>
                        {modeChanged !== undefined ? (
                            <p>DAY {modeChanged}일</p>
                        ) : (
                            <p>현재 모드 정보 없음</p>
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-between px-3 pb-2">
                    {connected ? <Wifi /> : <WifiOff className="text-fault" />}
                    <p className="ml-2">
                        {connected ? '연결 이후' : '연결 끊김'} {workingDay}일
                    </p>
                </div>
            </section>
        </div>
    );
};

export default SmartFarmListItem;
