import React, { useEffect } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { hangulIncludes } from '@toss/hangul';
import { motion } from 'framer-motion';
import SmartFarmListItem from '../../components/SmartFarm/SmartFarmListItem';
import { timeDifference } from '../../utils/datetime-util';
import {
    ecMean,
    phMean,
    targetECMean,
    targetPHMean,
} from '../../utils/data-util';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Loading/Error';
import { SmartFarm } from '../../types/type';
import { retrieveFavorite } from '../../utils/favorite-util';

type FarmListProp = {
    smartFarms: SmartFarm[];
    loading: number;
    error: string;
    refreshHandler: () => void;
};

/**
 * 스마트팜의 목록을 보여주는 view
 *
 * @component
 * @param {FarmListProp} props
 * @param {Array<SmartFarm>} props.smartFarms - 표기할 스마트팜의 목록
 * @param {number} props.loading - 스마트팜 목록을 로딩중인지 여부 - ( 0 - 시작, 100 - 끝 )
 * @param {string} props.error - 메시지 존재시 에러메시지임.
 * @param {Function} props.refreshHandler - 스마트팜 새로고침용 핸들러
 * @returns {JSX.Element} 스마트팜의 리스트를 보여주는 view
 */
const FarmList: React.FC<FarmListProp> = ({
    smartFarms,
    loading,
    error,
    refreshHandler,
}) => {
    useEffect(() => {
        document.title = '상상텃밭 IOT 모니터링';
    });

    const [search, setSearch] = React.useState('');

    const [, setRefreshList] = React.useState(false);

    if (loading < 100) {
        return <Loading loading={loading} />;
    }

    if (error !== '') {
        return <Error />;
    }

    return (
        <main className="flex-1 space-y-7">
            <input
                className="border-black-100 h-10 w-[100%] rounded-md border-[1px] px-2"
                placeholder="스마트팜 이름 검색"
                value={search}
                onChange={(e): void => setSearch(e.target.value)}
            />
            <section className="space-y-6">
                <div className="flex items-center gap-4">
                    <div className="text-xl font-bold">즐겨찾기 목록</div>
                    <div
                        onClick={(): void => {
                            refreshHandler();
                        }}
                        className="flex cursor-pointer text-[25px] hover:animate-spinonce"
                    >
                        <ReplayIcon fontSize="inherit" />
                    </div>
                </div>
                {retrieveFavorite().length === 0 && (
                    <div>즐겨찾기 한 스마트팜이 없습니다.</div>
                )}
                <section className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4 lg:gap-4 xl:grid-cols-5">
                    {smartFarms &&
                        smartFarms
                            .filter((farm) =>
                                retrieveFavorite().includes(farm.id)
                            )
                            .map((farm) => {
                                return (
                                    <SmartFarmListItem
                                        key={farm.id}
                                        mode={farm.mode}
                                        modeChanged={farm.modeChanged}
                                        name={farm.name}
                                        smartFarmId={farm.id}
                                        workingDay={timeDifference(
                                            farm.updatedAt
                                        )}
                                        ec={ecMean(farm.devices)}
                                        ph={phMean(farm.devices)}
                                        humidity={farm.humidity}
                                        temperature={farm.temperature}
                                        connected={farm.connection}
                                        targetEC={targetECMean(farm.devices)}
                                        targetPH={targetPHMean(farm.devices)}
                                        favorite={true}
                                        refreshList={setRefreshList}
                                    />
                                );
                            })}
                </section>
            </section>
            <section className="flex select-none justify-start gap-8">
                <p className="text-2xl font-bold">스마트팜 목록</p>
                <div
                    onClick={(): void => {
                        refreshHandler();
                    }}
                    className="flex cursor-pointer text-[30px] hover:animate-spinonce"
                >
                    <ReplayIcon fontSize="inherit" />
                </div>
            </section>
            <section className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4 lg:gap-4 xl:grid-cols-5">
                {smartFarms &&
                    smartFarms
                        .filter((farm) => !retrieveFavorite().includes(farm.id))
                        .filter(
                            (farm) =>
                                search === '' ||
                                hangulIncludes(farm.name, search)
                        )
                        .map((farm, index) => {
                            return (
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                        y: 10,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{
                                        delay: 0.015 * index,
                                        duration: 0.2,
                                    }}
                                >
                                    <SmartFarmListItem
                                        key={farm.id}
                                        mode={farm.mode}
                                        modeChanged={farm.modeChanged}
                                        name={farm.name}
                                        smartFarmId={farm.id}
                                        workingDay={timeDifference(
                                            farm.updatedAt
                                        )}
                                        ec={ecMean(farm.devices)}
                                        ph={phMean(farm.devices)}
                                        humidity={farm.humidity}
                                        temperature={farm.temperature}
                                        connected={farm.connection}
                                        targetEC={targetECMean(farm.devices)}
                                        targetPH={targetPHMean(farm.devices)}
                                        favorite={false}
                                        refreshList={setRefreshList}
                                    />
                                </motion.div>
                            );
                        })}
            </section>
        </main>
    );
};

export default FarmList;
