import React from 'react';
import { RangeType } from '../../consts/item-range';
import { round } from '../../utils/data-util';

type SingleStatusCardProp = {
    title: string;
    value?: number;
    range: RangeType[];
    target?: number;
};

const backgroundColor = (
    value: number | undefined,
    range: RangeType[]
): string => {
    if (value === undefined) return '#073B4C';
    if (range.length === 0) return '#F8F9FA';
    for (let i = 0; i < range.length; i += 1) {
        if (value > range[i].range[0] && value <= range[i].range[1]) {
            return range[i].color;
        }
    }
    return '#073B4C';
};

/**
 * 단일 상태를 표기하기 위한 카드 컴포넌트입니다.
 *
 * @component
 * @param {SingleStatusCardProp} props
 * @param {string} props.title - 상태 카드의 제목.
 * @param {string} [props.scale=''] - 상태 카드의 단위.
 * @param {number} props.value - 상태 카드의 값.
 * @param {number[]} props.range - 값이 허용되는 범위.
 * @param {string} props.measuredAt - 값을 측정한 시간.
 * @param {number} props.target - 목표값.
 * @returns {JSX.Element} 단일 상태 카드 컴포넌트
 */
const SmallSingleStatusCard: React.FC<SingleStatusCardProp> = ({
    title,
    value,
    range,
    target,
}) => {
    return (
        <div
            className="flex w-[100%] flex-col items-start justify-between rounded-sm border-[1px] border-grayscale-700 px-2 shadow-around"
            style={{
                backgroundColor: backgroundColor(value, range),
            }}
            data-testid="single-status-card"
        >
            <section>
                <p
                    className="text-lg font-[600]"
                    style={{ color: value === undefined ? '#F8F9FA' : 'black' }}
                >
                    {title}&nbsp;
                </p>
            </section>
            <section>
                <p
                    className="text-base font-bold"
                    style={{ color: value === undefined ? '#F8F9FA' : 'black' }}
                >
                    {value !== undefined
                        ? `${round(value ?? 0, 1).toFixed(0)} `
                        : '측정안됨'}
                    {target &&
                        `/ ${round(target, 1).toFixed(1).replace('.0', '')}`}
                </p>
            </section>
        </div>
    );
};

export default SmallSingleStatusCard;
