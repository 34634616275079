export const initFavorite = (): void => {
    if (localStorage.getItem('favorite') === null) {
        localStorage.setItem('favorite', JSON.stringify([]));
    }
};

export const addFavorite = (farmID: number): void => {
    if (localStorage.getItem('favorite') === null) {
        localStorage.setItem('favorite', JSON.stringify([]));
    }
    const favorite = JSON.parse(localStorage.getItem('favorite') as string);
    favorite.push(farmID);
    localStorage.setItem('favorite', JSON.stringify(favorite));
};

export const removeFavorite = (farmID: number): void => {
    if (localStorage.getItem('favorite') === null) {
        localStorage.setItem('favorite', JSON.stringify([]));
    }
    const favorite = JSON.parse(localStorage.getItem('favorite') as string);
    const newFavorite = favorite.filter((id: number) => id !== farmID);
    localStorage.setItem('favorite', JSON.stringify(newFavorite));
};

export const retrieveFavorite = (): number[] => {
    if (localStorage.getItem('favorite') === null) {
        localStorage.setItem('favorite', JSON.stringify([]));
    }
    return JSON.parse(localStorage.getItem('favorite') as string);
};
