/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Device, DeviceBackend, SmartFarm } from '../types/type';
import {
    currentDeviceMode,
    currentDeviceModeChanged,
    currentDevicePriority,
    ecMean,
    phMean,
} from '../utils/data-util';
import {
    RangeType,
    co2Range,
    ecRange,
    humidityRange,
    phRange,
    temperatureRange,
} from '../consts/item-range';

type UseSmartFarms = [SmartFarm[], number, string, () => void];

const getSeverity = (value: number | undefined, range: RangeType[]): number => {
    if (value === undefined) return -1;
    for (let i = 0; i < range.length; i += 1) {
        if (value >= range[i].range[0] && value < range[i].range[1]) {
            return range[i].severity;
        }
    }
    return 0;
};

/**
 * 스마트팜들의 데이터를 가져오는 커스텀 훅입니다.
 * ttl 값을 지정해 데이터가 얼마나 지속될지 정할수 있습니다. ( 밀리초 단위 )
 * 결과는 모드별로 정렬되어 나온다.
 * @returns 스마트팜들의 데이터, 로딩 상태, 에러, 리프레시 핸들러의 배열.
 * @example const [smartFarms, loading, error, refreshHandler] = useSmartFarms();
 */
const useSmartFarms = (): UseSmartFarms => {
    const [smartFarms, setSmartFarms] = useState<SmartFarm[]>([]);
    const [loading, setLoading] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [refresh, setRefresh] = useState<boolean>(false);

    // 데이터가 지속될 시간.
    const ttl = 600 * 1000;

    const refreshHandler = (): void => {
        localStorage.removeItem('farms');
        setRefresh((prev) => !prev);
    };

    const fetchData = async (): Promise<void> => {
        setLoading(0);
        if (
            localStorage.getItem('farms') !== null &&
            JSON.parse(localStorage.getItem('farms')!)[1] -
                new Date().getTime() >=
                0
        ) {
            const farms: SmartFarm[] = JSON.parse(
                localStorage.getItem('farms')!
            )[0];
            setSmartFarms(farms);
            setLoading(100);
        } else {
            const farms: SmartFarm[] = [];
            const promises = [];

            for (let id = 0; id <= 40; id += 1) {
                promises.push(
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/smartfarm/${id}`)
                        .then((res) => res.data)
                        .catch(() => {})
                );
            }

            await Promise.all(promises).then((datas): void => {
                datas.forEach((data) => {
                    if (data !== undefined) {
                        farms.push({
                            id: data.id,
                            name: data.name,
                            updatedAt: data.updatedAt,
                            mode: currentDeviceMode(data.devices),
                            modeChanged: currentDeviceModeChanged(data.devices),
                            priority: currentDevicePriority(data.devices),
                            updated: {},
                            devices:
                                data.devices &&
                                data.devices.map(
                                    (device: DeviceBackend): Device => {
                                        return {
                                            connection: device.connection,
                                            id: device.id,
                                            name: device.name,
                                            ec: device.measurer?.power
                                                ? device.measurer?.ec
                                                : undefined,
                                            ph: device.measurer?.power
                                                ? device.measurer?.ph
                                                : undefined,
                                            waterTemperature: device.measurer
                                                ?.power
                                                ? device.measurer
                                                      ?.waterTemperature
                                                : undefined,
                                            targetEC: device.measurer?.power
                                                ? device.measurer?.targetEC
                                                : undefined,
                                            targetPH: device.measurer?.power
                                                ? device.measurer?.targetPH
                                                : undefined,
                                            sku: device.sku,
                                            power: device.measurer?.power,
                                        };
                                    }
                                ),
                            connection:
                                data.devices &&
                                data.devices.length !==
                                    data.devices.reduce(
                                        (acc: number, cur: DeviceBackend) =>
                                            acc + (cur.connection ? 0 : 1),
                                        0
                                    ),
                        });
                    }
                    setLoading((prev) => prev + 50 / 40);
                });
            });

            setLoading(50);

            const result: SmartFarm[] = await Promise.all(
                farms.map(async (farm: SmartFarm) => {
                    const [
                        [temperature, humidity, temperatureUpdated],
                        [co2, co2Updated],
                    ] = await Promise.all([
                        axios
                            .get(
                                `${process.env.REACT_APP_API_URL}/smartfarmtemperaturehumidity/recent?smartFarmID=${farm.id}`
                            )
                            .then((res) => res.data)
                            .then((data) => [
                                data.temperature,
                                data.humidity,
                                data.updatedAt,
                                data.UpdatedAt,
                            ])
                            .catch(() => [undefined, undefined, undefined]),
                        axios
                            .get(
                                `${process.env.REACT_APP_API_URL}/smartfarmco2/recent?smartFarmID=${farm.id}`
                            )
                            .then((res) => res.data)
                            .then((data) => [data.co2, data.updatedAt])
                            .catch(() => [undefined, undefined]),
                    ]);

                    const updatedValue = {
                        ...farm.updated,
                        temperature: temperatureUpdated,
                        humidity: temperatureUpdated,
                        co2: co2Updated,
                    };

                    setLoading((prev) => prev + 50 / farms.length);

                    return {
                        ...farm,
                        temperature,
                        humidity,
                        co2,
                        updated: updatedValue,
                    };
                })
            );

            setLoading(100);

            if (farms.length === 0) {
                setError('스마트팜 목록을 가져올수 없습니다.');
            } else {
                result.sort((a, b) => {
                    if (a.priority !== b.priority) {
                        return a.priority - b.priority;
                    }
                    const severityA =
                        getSeverity(a.temperature, temperatureRange) +
                        getSeverity(a.humidity, humidityRange) +
                        getSeverity(a.co2, co2Range) +
                        getSeverity(ecMean(a.devices), ecRange) +
                        getSeverity(phMean(a.devices), phRange);
                    const severityB =
                        getSeverity(b.temperature, temperatureRange) +
                        getSeverity(b.humidity, humidityRange) +
                        getSeverity(b.co2, co2Range) +
                        getSeverity(ecMean(b.devices), ecRange) +
                        getSeverity(phMean(b.devices), phRange);

                    return severityB - severityA;
                });
                setSmartFarms(result);
                localStorage.setItem(
                    'farms',
                    JSON.stringify([result, new Date().getTime() + ttl])
                );
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [refresh]);

    return [smartFarms, loading, error, refreshHandler];
};

export default useSmartFarms;
