export type RangeType = {
    range: [number, number];
    color: string;
    severity: number;
};

/**
 * 온도 색상 범위
 */
export const temperatureRange: RangeType[] = [
    {
        range: [Number.MIN_VALUE, 15],
        color: '#3A86FF',
        severity: 1,
    },
    {
        range: [15, 23],
        color: '#06D6A0',
        severity: 0,
    },
    {
        range: [23, Number.MAX_VALUE],
        color: '#EF476F',
        severity: 1,
    },
];

/**
 * 습도 색상 범위
 */
export const humidityRange: RangeType[] = [
    {
        range: [Number.MIN_VALUE, 40],
        color: '#FFD166',
        severity: 1,
    },
    {
        range: [40, 60],
        color: '#06D6A0',
        severity: 0,
    },
    {
        range: [60, Number.MAX_VALUE],
        color: '#EF476F',
        severity: 1,
    },
];

/**
 * 이산화탄소 색상 범위
 */
export const co2Range: RangeType[] = [
    {
        range: [Number.MIN_VALUE, 1500],
        color: '#06D6A0',
        severity: 0,
    },
    {
        range: [1500, Number.MAX_VALUE],
        color: '#FFD166',
        severity: 1,
    },
];

/**
 * EC 색상 범위
 */
export const ecRange: RangeType[] = [
    {
        range: [Number.MIN_VALUE, 600],
        color: '#3A86FF',
        severity: 1,
    },
    {
        range: [600, 1500],
        color: '#06D6A0',
        severity: 0,
    },
    {
        range: [1500, Number.MAX_VALUE],
        color: '#EF476F',
        severity: 1,
    },
];

/**
 * 산성도 색상 범위
 */
export const phRange: RangeType[] = [
    {
        range: [Number.MIN_VALUE, 5.0],
        color: '#EF476F',
        severity: 12,
    },
    {
        range: [5.0, 5.5],
        color: '#FFD166',
        severity: 6,
    },
    {
        range: [5.5, 6.5],
        color: '#06D6A0',
        severity: 0,
    },
    {
        range: [6.5, 7.0],
        color: '#FFD166',
        severity: 6,
    },
    {
        range: [7.0, Number.MAX_VALUE],
        color: '#EF476F',
        severity: 12,
    },
];

export const waterTemperatureRange: RangeType[] = [];
